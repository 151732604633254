import React, { useState, useRef } from "react";
import { Input, Button, Row, Col, Card } from "antd";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";

export const Footer = () => {
  const form = useRef();
  const [captcha, setCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);

  function onChange(value) {
    setCaptcha(value !== null);
  }

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_PUBLIC_KEY' with your actual values
    emailjs
      .sendForm(
        "service_qrw5b5m",
        "template_gvvgo59",
        form.current,
        "OtMyoaYzn6w5eB2IT"
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
          form.setFieldsValue({
            name: "",
            email: "",
            message: "",
          });
          setLoading(false);
        },
        (error) => {
          console.error("Error sending message:", error.text);
          alert("Failed to send message. Please try again.");
          setLoading(false);
        }
      );
  };

  return (
    <div 
    // style={{ paddingTop: "30px" }}
    >
      <footer id="contact-us">
        <div className="container footer-content">
          {/* New Contact Section */}
          <div className="col-lg-8 offset-lg-2">
            <div className="center-heading">
              <h2>
                Contact Us <em>We're Here to Help</em>
              </h2>
              <p>
                If you have any questions or inquiries, feel free to reach out
                to us. We are always happy to assist you.
              </p>
            </div>
          </div>

          <Row gutter={[16, 16]} justify="center">
            {/* Address, phone, and email section */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)",
                  height: "170px",
                  backgroundColor: "#fff",
                  transition:
                    "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.backgroundColor = "#f0f8ff";
                  e.currentTarget.style.boxShadow =
                    "0 8px 24px rgba(0, 0, 0, 0.3)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.backgroundColor = "#fff";
                  e.currentTarget.style.boxShadow =
                    "0 4px 16px rgba(0, 0, 0, 0.2)";
                }}
              >
                <FaMapMarkerAlt
                  style={{ fontSize: "40px", color: "#5A8CB1" }}
                />
                <p
                  style={{
                    fontSize: "16px",
                    color: "#555",
                    margin: "15px 0 0 0",
                    lineHeight: "1.5",
                  }}
                >
                  Paramount Tower (Nawala),
                  <br /> Level 2 to 5, No 192/10, 9th Lane,
                  <br />
                  Nawala Road, Kotte, Sri Lanka.
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)",
                  height: "170px",
                  backgroundColor: "#fff",
                  transition:
                    "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.backgroundColor = "#f0f8ff";
                  e.currentTarget.style.boxShadow =
                    "0 8px 24px rgba(0, 0, 0, 0.3)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.backgroundColor = "#fff";
                  e.currentTarget.style.boxShadow =
                    "0 4px 16px rgba(0, 0, 0, 0.2)";
                }}
              >
                <FaPhoneAlt style={{ fontSize: "40px", color: "#5A8CB1" }} />
                <p
                  style={{
                    fontSize: "16px",
                    color: "#555",
                    margin: "15px 0 0 0",
                    lineHeight: "1.5",
                  }}
                >
                  +94 7777 24 995
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)",
                  height: "170px",
                  backgroundColor: "#fff",
                  transition:
                    "transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.backgroundColor = "#f0f8ff";
                  e.currentTarget.style.boxShadow =
                    "0 8px 24px rgba(0, 0, 0, 0.3)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.backgroundColor = "#fff";
                  e.currentTarget.style.boxShadow =
                    "0 4px 16px rgba(0, 0, 0, 0.2)";
                }}
              >
                <IoMailSharp style={{ fontSize: "40px", color: "#5A8CB1" }} />
                <p
                  style={{
                    fontSize: "16px",
                    color: "#555",
                    margin: "15px 0 0 0",
                    lineHeight: "1.5",
                  }}
                >
                  connect@ceyontech.com
                </p>
              </Card>
            </Col>
          </Row>

          <div className="row mt-3">
            <div className="col-md-6 pb-5">
              <iframe
                style={{
                  width: "100%",
                  height: "392px",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.046875688388!2d79.88539687574674!3d6.88498871885553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25ba65b36a86f%3A0xc047241a1247bfd5!2sRegus%20-%20Kotte%2C%20Paramount%20Tower%20(Nawala)!5e0!3m2!1sen!2slk!4v1727162298232!5m2!1sen!2slk"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>

            <div className="col-md-6 pb-5">
              <div
                className="contact-form"
                style={{
                  height: "392px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "30px",
                }}
              >
                <form onSubmit={handleSubmit} ref={form} style={{ flex: 1 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        id="name"
                        name="name"
                        placeholder="Name"
                        style={{ height: "40px", borderRadius: "5px" }}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        id="email"
                        name="email"
                        placeholder="Email"
                        style={{ height: "40px", borderRadius: "5px" }}
                        required
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <Input.TextArea
                        id="message"
                        name="message"
                        placeholder="Message"
                        style={{ height: "100px", borderRadius: "5px" }}
                        required
                      />
                    </div>
                  </div>

                  <div className="mt-3" style={{ textAlign: "center" }}>
                    <ReCAPTCHA
                      sitekey="6LeF8HUqAAAAANM5uZ_4Yn69zCxxL1wZLs0k0CWh"
                      onChange={onChange}
                    />
                  </div>

                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                      backgroundColor: "#5A8CB1",
                      borderColor: "#5A8CB1",
                      borderRadius: "5px",
                      marginTop: "20px",
                    }}
                    disabled={!captcha}
                    loading={loading}
                  >
                    {loading ? "Sending..." : "Send Message"}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
