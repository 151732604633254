import { Drawer } from "antd";
import React, { useEffect, useState } from "react";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("#welcome");

  useEffect(() => {
    if (open) {
      const drawerContent = document.querySelector(".drawer-content");
      const menuItems = document.querySelectorAll(".scroll-to-section");
      if (drawerContent) {
        drawerContent.classList.add("show");
      }
      menuItems.forEach((item, index) => {
        setTimeout(() => {
          item.classList.add("show");
        }, index * 100); // Staggered effect
      });
    } else {
      const drawerContent = document.querySelector(".drawer-content");
      const menuItems = document.querySelectorAll(".scroll-to-section");
      if (drawerContent) {
        drawerContent.classList.remove("show");
      }
      menuItems.forEach((item) => {
        item.classList.remove("show");
      });
    }
  }, [open]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  
    // Dynamically detect the active section based on scroll position
    const sections = ["#welcome", "#about", "#testimonials", "#contact-us"];
    sections.forEach((section) => {
      const element = document.querySelector(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 150 && rect.bottom >= 150) {
          setActive(section);
        }
      }
    });
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    targetElement.scrollIntoView({ behavior: "smooth" });
    setActive(`#${targetId}`);
    setOpen(false); // Close drawer after clicking an item
  };

  const openDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <div>
      <header className="header-area header-sticky">
        <div
          style={{
            backgroundColor: isScrolled ? "white" : "transparent",
            transition: "background-color 0.3s ease",
            boxShadow: isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.3)" : "none",
            color: isScrolled ? "black" : "inherit",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  <a
                    href="index.html"
                    className="logo"
                    style={{ color: isScrolled ? "black" : "" }}
                  >
                    CEYON TECH
                  </a>
                  <ul className="nav">
                    <li className="scroll-to-section show">
                      <a
                        href="#welcome"
                        className={`menu-item ${
                          active === "#welcome" ? "active" : ""
                        }`}
                        onClick={handleClick}
                      >
                        Home
                      </a>
                    </li>
                    <li className="scroll-to-section show">
                      <a
                        href="#about"
                        className={`menu-item ${
                          active === "#about" ? "active" : ""
                        }`}
                        onClick={handleClick}
                      >
                        About
                      </a>
                    </li>
                    
                    <li className="scroll-to-section show">
                      <a
                        href="#services"
                        className={`menu-item ${
                          active === "#services" ? "active" : ""
                        }`}
                        onClick={handleClick}
                      >
                        Services
                      </a>
                    </li>
                    <li className="scroll-to-section show">
                      <a
                        href="#products"
                        className={`menu-item ${
                          active === "#products" ? "active" : ""
                        }`}
                        onClick={handleClick}
                      >
                        Products
                      </a>
                    </li>
                    {/* <li className="scroll-to-section show">
                      <a
                        href="#testimonials"
                        className={`menu-item ${
                          active === "#testimonials" ? "active" : ""
                        }`}
                        onClick={handleClick}
                      >
                        Testimonials
                      </a>
                    </li> */}
                    <li className="scroll-to-section show">
                      <a
                        href="#contact-us"
                        className={`menu-item ${
                          active === "#contact-us" ? "active" : ""
                        } `}
                        onClick={handleClick}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                  <a
                    className="menu-trigger"
                    style={{ color: isScrolled ? "black" : "inherit" }}
                    onClick={openDrawer}
                  >
                    <span>Menu</span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <Drawer
          title="Menu"
          placement="right"
          onClose={closeDrawer}
          open={open}
          className="drawer"
        >
          <div className="drawer-content">
            <ul className="drawer-menu">
              <li className="scroll-to-section">
                <a
                  href="#welcome"
                  className={`drawer-heading ${
                    active === "#welcome" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <h4>Home</h4>
                </a>
              </li>
              <li className="scroll-to-section">
                <a
                  href="#about"
                  className={`drawer-heading ${
                    active === "#about" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <h4>About</h4>
                </a>
              </li>
              {/* <li className="scroll-to-section">
                <a
                  href="#testimonials"
                  className={`drawer-heading ${
                    active === "#testimonials" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <h4>Testimonials</h4>
                </a>
              </li> */}
               <li className="scroll-to-section">
                <a
                  href="#services"
                  className={`drawer-heading ${
                    active === "#services" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <h4>Services</h4>
                </a>
              </li>
              <li className="scroll-to-section">
                <a
                  href="#products"
                  className={`drawer-heading ${
                    active === "#products" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <h4>Products</h4>
                </a>
              </li>
              <li className="scroll-to-section">
                <a
                  href="#contact-us"
                  className={`drawer-heading ${
                    active === "#contact-us" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <h4>Contact Us</h4>
                </a>
              </li>
            </ul>
          </div>
        </Drawer>
      </header>
    </div>
  );
};
