import React, { useState } from "react";
import ficon1 from "../assests/images/features-icon-1.png";
import ficon2 from "../assests/images/features-icon-2.png";
import ficon3 from "../assests/images/features-icon-3.png";
import leftimg from "../assests/images/left-image-2.png";
import { Modal } from "antd";
import { BsBuildingFillGear } from "react-icons/bs";
import {
  GiBookmarklet,
  GiRobotGolem,
  GiServerRack,
  GiTeamUpgrade,
} from "react-icons/gi";

export const About = () => {
  const [open, setOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState({});

  const serviceData = [
    {
      title: "ERP Implementation and Consulting",
      summary:
        "Tailored ERP solutions streamline operations, optimize processes, and enhance productivity through expert guidance and change management.",
      description:
        "Our proven ERP Implementation service involves the deployment of Enterprise Resource Planning (ERP) systems tailored to a company's specific needs. It includes assessing the current business processes, selecting the appropriate ERP software, and customizing it to enhance operational efficiency. The implementation process typically involves project management, data migration, user training, and system testing to ensure a smooth transition and minimal disruption to ongoing operations. CeyonTech’s ERP consulting services focus on guiding organizations through the complexities of selecting, implementing, and optimizing ERP systems. Consultants analyze business processes, identify inefficiencies, and recommend best practices to maximize the value of the ERP investment. This may also include change management strategies to ensure that employees adapt to the new system effectively, thereby improving overall productivity and facilitating digital transformation.",
      icon: <BsBuildingFillGear size={100} color="#5A8CB1" />,
    },
    {
      title: "Corporate eLearning and Course Digitalization",
      summary:
        "Flexible digital solutions enhance workforce training, reducing costs and improving accessibility, engagement, and knowledge retention.",
      description:
        "Our corporate eLearning and course digitalization provide organizations with flexible, scalable, and engaging solutions for workforce training and development. Corporate eLearning utilizes digital technology to deliver interactive and accessible training programs, allowing employees to learn at their own pace and from any location, enhancing engagement and knowledge retention​. On the other hand, course digitalization involves transforming traditional training materials into digital formats, making them accessible online and improving tracking and assessment capabilities​. Together, these services help organizations reduce training costs, ensure consistent training experiences, and effectively develop their workforce in a rapidly changing business environment.",
      icon: <GiBookmarklet size={100} color="#5A8CB1" />,
    },
    {
      title: "Resource Augmentation",
      summary:
        "Flexible staffing solutions enhance teams with specialized talent, adapting quickly to project demands without long-term commitments.",
      description:
        "A strategic service designed to help organizations enhance their workforce by providing specialized skills and expertise on a permanent/temporary basis. This approach allows companies to quickly scale their teams up or down to meet project demands without the long-term commitments of hiring full-time employees. Whether you need to boost your team for a short-term project or require ongoing support, our expert professionals seamlessly integrate into your workforce, ensuring that you have the right talent at the right time. This not only enhances operational efficiency but also allows you to adapt swiftly to changing market conditions while minimizing overhead costs. By leveraging our Resource Augmentation Services, organizations can tap into a broader talent pool, drive innovation, and maintain a competitive edge in their industry. Let us help you build a resilient workforce that meets your unique business needs.",
      icon: <GiTeamUpgrade size={100} color="#5A8CB1" />,
    },
    {
      title: "IT Infrastructure Services",
      summary:
        "Tailored IT infrastructure services optimize management, enhance security, and enable growth through scalable solutions.",
      description:
        "At Ceyon Technologies, we understand that a robust IT infrastructure is the backbone of successful business operations. Our IT Infrastructure Services are designed to provide organizations with the essential components needed to optimize their IT management. This includes a comprehensive suite of hardware, software, and networking solutions tailored to meet your unique business requirements. We specialize in building and maintaining both traditional and cloud-based infrastructure, ensuring seamless communication, efficient data management, and enhanced security. Our services not only facilitate smooth day-to-day operations but also support scalability, enabling your organization to adapt and grow in a rapidly changing digital landscape. By leveraging cutting-edge technology and industry best practices, we empower businesses to foster innovation and enhance their operational agility. Whether you’re looking to upgrade your existing systems or embark on a digital transformation journey, our IT infrastructure solutions will help you lay a strong foundation for future success.",
      icon: <GiServerRack size={100} color="#5A8CB1" />,
    },
    {
      title: "Industry AI Automation and Process Automation Services",
      summary:
        "AI-powered automation improves efficiency, reduces human error, and drives growth through machine learning and streamlined workflows.",
      description:
        "We specialize in Industry AI Automation and Process Automation Services that leverage cutting-edge technologies to optimize your business operations. Our approach focuses on integrating advanced Machine Learning algorithms to automate repetitive tasks, streamline workflows, and enhance overall productivity. By deploying AI-driven solutions tailored to your industry’s specific needs, we enable organizations to make data-driven decisions, improve operational efficiency, and gain a competitive edge in the market. This transformation not only minimizes human error but also frees up valuable resources, allowing teams to concentrate on strategic initiatives. Discover how our automation services can revolutionize your operations and drive growth in today’s fast-paced business environment.",
      icon: <GiRobotGolem size={100} color="#5A8CB1" />,
    },
  ];

  const handleReadMore = (index) => {
    console.log(index);
    setSelectedContent(serviceData[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContent({});
  };

  return (
    <div>
      <section className="section" id="about">
        <div className="container about-area">
          <div className="row my-2">
            <div
              className="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <img
                src={leftimg}
                style={{scale:"1.25"}}
                className="rounded img-fluid d-block mx-auto"
                alt="App"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-heading center-heading">
                <div className="col-lg-8 offset-lg-2">
                  <div className="center-heading">
                    <h2>
                      About <em>Us</em>
                    </h2>
                    <p>
                      Our team is dedicated to providing exceptional service and
                      ensuring a positive experience for everyone we work with.
                    </p>
                  </div>
                </div>

                <p className="about-area about-text">
                  CEYON TECHNOLOGIES is an emerging force in the Information
                  Technology sector, founded by a team of seasoned professionals
                  with a shared vision of driving innovation in the digital
                  world. Our company combines deep industry knowledge with a
                  commitment to delivering cutting-edge solutions that address
                  complex challenges and drive technological advancement.
                </p>
                <p>
                  Our team’s extensive experience in Enterprise ERP Solutions,
                  Technology Consulting, Resource Augmentation, Bespoke Industry
                  Verticals, and unmatched customer service is the cornerstone
                  of our approach. With backgrounds from leading firms in the IT
                  sector, we bring a unique perspective and expertise that
                  informs our strategic initiatives and ensures the development
                  of impactful solutions that meet the evolving needs of
                  businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Modal open={open} onCancel={handleClose} footer={null}>
          <div className="d-flex flex-column justify-content-center">
            <h6>{selectedContent.title}</h6>
            <p className="mt-4">{selectedContent.description}</p>
          </div>
        </Modal>
      </section>
    </div>
  );
};
