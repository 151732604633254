import React, { useState } from "react";
import ficon1 from "../assests/images/features-icon-1.png";
import ficon2 from "../assests/images/features-icon-2.png";
import ficon3 from "../assests/images/features-icon-3.png";
import leftimg from "../assests/images/left-image.png";
import img from "../assests/images/4.png";
import { Button, Modal } from "antd";
import "../Component/WhatsAppBtn.css";
import { BsBuildingFillGear } from "react-icons/bs";
import {
  GiBookmarklet,
  GiRobotGolem,
  GiServerRack,
  GiTeamUpgrade,
} from "react-icons/gi";

export const Services = () => {
  const [open, setOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState({});

  const serviceData = [
    {
      title: "ERP Implementation and Consulting",
      summary:
        "Tailored ERP solutions streamline operations, optimize processes, and enhance productivity through expert guidance and change management.",
      description: (
        <div className=" d-flex flex-column">
          <p className="mt-2" style={{color:"black"}}>
            Our proven ERP Implementation service involves the deployment of
            Enterprise Resource Planning (ERP) systems tailored to a company's
            specific needs. It includes assessing the current business
            processes, selecting the appropriate ERP software, and customizing
            it to enhance operational efficiency.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            The implementation process typically involves project management,
            data migration, user training, and system testing to ensure a smooth
            transition and minimal disruption to ongoing operations. CeyonTech’s
            ERP consulting services focus on guiding organizations through the
            complexities of selecting, implementing, and optimizing ERP systems.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            Consultants analyze business processes, identify inefficiencies, and
            recommend best practices to maximize the value of the ERP
            investment. This may also include change management strategies to
            ensure that employees adapt to the new system effectively, thereby
            improving overall productivity and facilitating digital
            transformation.
          </p>
        </div>
      ),
      icon: <BsBuildingFillGear size={100} color="#5A8CB1" />,
      iconModal: (
        <BsBuildingFillGear size={"calc(90% - 10px)"} color="#5A8CB1" />
      ),
    },
    {
      title: "Corporate eLearning and Course Digitalization",
      summary:
        "Flexible digital solutions enhance workforce training, reducing costs and improving accessibility, engagement, and knowledge retention.",
      // description:
      //   "Our corporate eLearning and course digitalization provide organizations with flexible, scalable, and engaging solutions for workforce training and development. Corporate eLearning utilizes digital technology to deliver interactive and accessible training programs, allowing employees to learn at their own pace and from any location, enhancing engagement and knowledge retention​. On the other hand, course digitalization involves transforming traditional training materials into digital formats, making them accessible online and improving tracking and assessment capabilities​. Together, these services help organizations reduce training costs, ensure consistent training experiences, and effectively develop their workforce in a rapidly changing business environment.",
      description: (
        <div className=" d-flex flex-column">
          <p className="mt-2" style={{color:"black"}}>
            Our corporate eLearning and course digitalization provide
            organizations with flexible, scalable, and engaging solutions for
            workforce training and development. Corporate eLearning utilizes
            digital technology to deliver interactive and accessible training
            programs, allowing employees to learn at their own pace and from any
            location, enhancing engagement and knowledge retention​.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            On the other hand, course digitalization involves transforming
            traditional training materials into digital formats, making them
            accessible online and improving tracking and assessment
            capabilities​. Together, these services help organizations reduce
            training costs, ensure consistent training experiences, and
            effectively develop their workforce in a rapidly changing business
            environment.
          </p>
        </div>
      ),
      icon: <GiBookmarklet size={100} color="#5A8CB1" />,
      iconModal: <GiBookmarklet size={"calc(90% - 10px)"} color="#5A8CB1" />,
    },
    {
      title: "Resource Augmentation",
      summary:
        "Flexible staffing solutions enhance teams with specialized talent, adapting quickly to project demands without long-term commitments.",
      // description:
      //   "A strategic service designed to help organizations enhance their workforce by providing specialized skills and expertise on a permanent/temporary basis. This approach allows companies to quickly scale their teams up or down to meet project demands without the long-term commitments of hiring full-time employees. Whether you need to boost your team for a short-term project or require ongoing support, our expert professionals seamlessly integrate into your workforce, ensuring that you have the right talent at the right time. This not only enhances operational efficiency but also allows you to adapt swiftly to changing market conditions while minimizing overhead costs. By leveraging our Resource Augmentation Services, organizations can tap into a broader talent pool, drive innovation, and maintain a competitive edge in their industry. Let us help you build a resilient workforce that meets your unique business needs.",
      description: (
        <div className=" d-flex flex-column">
          <p className="mt-2" style={{color:"black"}}>
            A strategic service designed to help organizations enhance their
            workforce by providing specialized skills and expertise on a
            permanent/temporary basis. This approach allows companies to quickly
            scale their teams up or down to meet project demands without the
            long-term commitments of hiring full-time employees.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            Whether you need to boost your team for a short-term project or
            require ongoing support, our expert professionals seamlessly
            integrate into your workforce, ensuring that you have the right
            talent at the right time. This not only enhances operational
            efficiency but also allows you to adapt swiftly to changing market
            conditions while minimizing overhead costs.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            By leveraging our Resource Augmentation Services, organizations can
            tap into a broader talent pool, drive innovation, and maintain a
            competitive edge in their industry. Let us help you build a
            resilient workforce that meets your unique business needs.
          </p>
        </div>
      ),
      icon: <GiTeamUpgrade size={100} color="#5A8CB1" />,
      iconModal: <GiTeamUpgrade size={"calc(90% - 10px)"} color="#5A8CB1" />,
    },
    {
      title: "IT Infrastructure Services",
      summary:
        "Tailored IT infrastructure services optimize management, enhance security, and enable growth through scalable solutions.",
      // description:
      //   "At Ceyon Technologies, we understand that a robust IT infrastructure is the backbone of successful business operations. Our IT Infrastructure Services are designed to provide organizations with the essential components needed to optimize their IT management. This includes a comprehensive suite of hardware, software, and networking solutions tailored to meet your unique business requirements. We specialize in building and maintaining both traditional and cloud-based infrastructure, ensuring seamless communication, efficient data management, and enhanced security. Our services not only facilitate smooth day-to-day operations but also support scalability, enabling your organization to adapt and grow in a rapidly changing digital landscape. By leveraging cutting-edge technology and industry best practices, we empower businesses to foster innovation and enhance their operational agility. Whether you’re looking to upgrade your existing systems or embark on a digital transformation journey, our IT infrastructure solutions will help you lay a strong foundation for future success.",
      description: (
        <div className=" d-flex flex-column">
          <p className="mt-2" style={{color:"black"}}>
            At Ceyon Technologies, we understand that a robust IT infrastructure
            is the backbone of successful business operations. Our IT
            Infrastructure Services are designed to provide organizations with
            the essential components needed to optimize their IT management.
            This includes a comprehensive suite of hardware, software, and
            networking solutions tailored to meet your unique business
            requirements.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            We specialize in building and maintaining both traditional and
            cloud-based infrastructure, ensuring seamless communication,
            efficient data management, and enhanced security. Our services not
            only facilitate smooth day-to-day operations but also support
            scalability, enabling your organization to adapt and grow in a
            rapidly changing digital landscape.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            By leveraging cutting-edge technology and industry best practices,
            we empower businesses to foster innovation and enhance their
            operational agility. Whether you’re looking to upgrade your existing
            systems or embark on a digital transformation journey, our IT
            infrastructure solutions will help you lay a strong foundation for
            future success.
          </p>
        </div>
      ),
      icon: <GiServerRack size={100} color="#5A8CB1" />,
      iconModal: <GiServerRack size={"calc(90% - 10px)"} color="#5A8CB1" />,
    },
    {
      title: "Industry AI Automation and Process Automation Services",
      summary:
        "AI-powered automation improves efficiency, reduces human error, and drives growth through machine learning and streamlined workflows.",
      // description:
      //   "We specialize in Industry AI Automation and Process Automation Services that leverage cutting-edge technologies to optimize your business operations. Our approach focuses on integrating advanced Machine Learning algorithms to automate repetitive tasks, streamline workflows, and enhance overall productivity. By deploying AI-driven solutions tailored to your industry’s specific needs, we enable organizations to make data-driven decisions, improve operational efficiency, and gain a competitive edge in the market. This transformation not only minimizes human error but also frees up valuable resources, allowing teams to concentrate on strategic initiatives. Discover how our automation services can revolutionize your operations and drive growth in today’s fast-paced business environment.",
      description: (
        <div className=" d-flex flex-column">
          <p className="mt-2" style={{color:"black"}}>
            We specialize in Industry AI Automation and Process Automation
            Services that leverage cutting-edge technologies to optimize your
            business operations. Our approach focuses on integrating advanced
            Machine Learning algorithms to automate repetitive tasks, streamline
            workflows, and enhance overall productivity.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            By deploying AI-driven solutions tailored to your industry’s
            specific needs, we enable organizations to make data-driven
            decisions, improve operational efficiency, and gain a competitive
            edge in the market. This transformation not only minimizes human
            error but also frees up valuable resources, allowing teams to
            concentrate on strategic initiatives.
          </p>
          <p className="mt-2" style={{color:"black"}}>
            Discover how our automation services can revolutionize your
            operations and drive growth in today’s fast-paced business
            environment.
          </p>
        </div>
      ),
      icon: <GiRobotGolem size={100} color="#5A8CB1" />,
      iconModal: <GiRobotGolem size={"calc(90% - 10px)"} color="#5A8CB1" />,
    },
  ];

  const handleReadMore = (index) => {
    console.log(index);
    setSelectedContent(serviceData[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedContent({});
  };

  return (
    <div>
      <section className="section" id="services">
        <div
          className="container "
          style={{ padding: "100px 0px 0px 0px", position: "relative", zIndex: "9" }}
        >
          <div className="col-lg-8 offset-lg-2">
            <div className="center-heading">
              <h2>
                Our <em>Services</em>
              </h2>
              <p>
                We provide a comprehensive range of services tailored to help
                you achieve your goals, with a focus on quality and customer
                satisfaction.
              </p>
            </div>
          </div>

          <div className="row mt-4 flex justify-content-center">
            {serviceData.map((service, index) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-2 mb-4"
                data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
              >
                <div className="features-item" style={{ height: "550px" }}>
                  <div
                    className="features-icon d-flex flex-column justify-content-between"
                    style={{ height: "100%" }}
                  >
                    <div>
                      <h2
                        style={{
                          zIndex: "-1",
                          color: "#5A8CB1",
                          opacity: "30%",
                        }}
                      >
                        0{index + 1}
                      </h2>
                      {/* <img src={ficon1} alt="" /> */}
                      <div className="mb-4">{service.icon}</div>
                      <h4>{service.title}</h4>
                      <p>{service.summary}</p>
                    </div>
                    <a
                      className="main-button"
                      onClick={() => handleReadMore(index)}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Modal open={open} onCancel={handleClose} footer={null} centered>
            <div
              className="d-flex flex-column justify-content-center"
              style={{ position: "relative", padding: "20px" }}
            >
              {/* Watermark Icon */}
              <div
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "5%",
                  opacity: "0.30",
                  width: "90%", // Set the width of the icon to 90% of the modal
                  height: "auto",
                  zIndex: 0,
                  textAlign: "center",
                }}
              >
                <div style={{ fontSize: "calc(90% - 10px)" }}>
                  {" "}
                  {/* Dynamically adjust font size */}
                  {selectedContent.iconModal}
                </div>
              </div>

              <div
                style={{ zIndex: 1, position: "relative", textAlign: "center" }}
              >
                <h6
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#5A8CB1",
                  }}
                >
                  {selectedContent.title}
                </h6>
                <p
                  className="mt-4"
                  style={{ fontSize: "16px", lineHeight: "1.6", color: "#000" }}
                >
                  {selectedContent.description}
                </p>
              </div>
              <div className="d-flex justify-content-center mt-4">
                {/* <a className="main-button" onClick={handleClose}> */}
                <div
                  className="floating-whatsapp floating-icon"
                  onClick={() => {
                    handleClose();
                    window.location.href = "#contact-us";
                  }}
                >
                  <img
                    className="floating-whatsapp floating-icon"
                    style={{ padding: "-150px", cursor: "pointer" }}
                    src={img}
                    alt="whatsapp"
                    height={"50px"}
                  />
                </div>
                {/* </a> */}
              </div>
            </div>
          </Modal>
        </div>
      </section>
    </div>
  );
};
