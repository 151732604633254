import React from "react";
import "./WhatsAppBtn.css";
import img from "../assests/images/4.png";
import { Button, Tooltip } from "antd";
import { FcContacts } from "react-icons/fc";
import { RiCustomerService2Line } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

export const WhatsAppBtn = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div
      className="floating-whatsapp"
      style={{
        zIndex: "999",
        position: "fixed",
        transition: "0.3s",
        bottom: isTabletOrMobile ? "-5%" : "-2%",
        right: isTabletOrMobile ? "-20%" : "-2%",
        // display: "flex",
        alignItems: "end",
        // gap: "10px",
        right: "50px",
        bottom: "50px",
      }}
    >
      <a
        onClick={() => {
          window.location.href = "#contact-us";
        }}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={img}
          alt="whatsapp"
          className="  floating-icon"
          style={{ width: "150px  ", height: "auto" }}
        />
      </a>
      {/* <div>
        
        <Tooltip title="On WhatsApp" placement="left" color="#5A8CB1">
          <Button
            onClick={() => {
              window.open("https://wa.me/94777724995", "_blank");
            }}
            type="default"
            style={{ borderRadius: "100%", height: "50px", width: "50px" }}
          >
            <FaWhatsapp color="#5A8CB1" size={20} />
          </Button>
        </Tooltip>
      </div> */}
      {/* <div className=""> */}
      {/* <Tooltip title="Contact Us" placement="left" > */}
      {/* <Button
            onClick={() => {
              window.location.href = "#contact-us";
            }}
            type="primary"
            style={{ borderRadius: "100%", height: "50px", width: "50px" }}
          > */}
      {/* <RiCustomerService2Line size={20} /> */}
      {/* <img src={img} alt="whatsapp" style={{ width: "15%" }} /> */}
      {/* </Button> */}
      {/* </Tooltip> */}
      {/* <Button icon={<RiCustomerService2Line/>} type="primary" className="floating-text">Contact Us</Button> */}
      {/* </div> */}
    </div>
  );
};
