import React, { useState, useEffect } from 'react';
import { About } from './Component/About';
import { Footer } from './Component/Footer';
import { Header } from './Component/Header';
import { Products, Promotion } from './Component/Products';
import { Testimonials } from './Component/Testimonials';
import { WelcomArea } from './Component/WelcomArea';
import { Preloader } from './Component/Preloader';
import { WhatsAppBtn } from './Component/WhatsAppBtn';
import { Services } from './Component/Services';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500); // 2 seconds delay
    return () => clearTimeout(timer);
  }, []);
  const data0 = `
  888888ba           oo dP       dP 
  88     8b             88       88 
  88aaaa8P' dP    dP dP 88 .d888b88 
  88    8b. 88    88 88 88 88    88 
  88    .88 88.  .88 88 88 88.  .88 
  88888888P  88888P  dP dP  88888P8 
 `;
 
 const data1 = `
 d8888888P                            
     .d8'                            
    .d8'   .d8888b. 88d888b. .d8888b. 
  .d8'     88'   88 88'   88 88ooood8 
 d8'       88.  .88 88    88 88.  ... 
 Y8888888P  88888P' dP    dP  88888P'
 `;
 
 const data2 = `
           d888888P d888888P 
              88       88    
              88       88    
              88       88    
              88       88    
           d888888P    dP   
 `;
 
 const massage=`Welcome to BuildZone IT`
 
 const conditionalConsoleLog = (message, color) => {
   const shouldShowLogs = true; // Set this flag based on your condition
 
   if (shouldShowLogs) {
     console.log(`%c${message}`, `color: ${color};`);
   }
 };
 
 const fetchdata = async() => {
   conditionalConsoleLog(data0, 'red');
   conditionalConsoleLog(data1, 'green');
   conditionalConsoleLog(data2, 'blue');
 }
 
 useEffect(() => {
   fetchdata();
   console.error = function() {};
   console.warn = function() {};
   console.assert = function() {};
   console.info = function() {};
   console.debug = function() {};
   console.info = function() {};
   
   
 }, []);

  return (
    <div>
      <Header />
      <WhatsAppBtn />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <WelcomArea id="welcome" />
          <div className="left-image-decor"></div>
          <About id="about" />
          <div className="right-image-decor"></div>
          <Services id="services"/>
          <div className="left-image-decor"></div>
          <Products id="products" />
          {/* <div className="right-image-decor"></div> */}
          {/* <Testimonials id="testimonials" /> */}
          <Footer id="footer" />
        </>
      )}
    </div>
  );
}

export default App;
