import React from "react";
import img from "../assests/images/banner-bg.png";
import { useMediaQuery } from "react-responsive";

export const WelcomArea = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div style={{ position: "relative" }}>
      <div className="background-img">
        <img src={img} alt="background" />
      </div>
      <div className="welcome-area" id="welcome">
        <div className="header-text">
          <div className="container">
            <div className="row">
              <div
                className="left-text col-lg-6 col-md-12 col-sm-12 col-xs-12"
                data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
              >
                {isTabletOrMobile ? (
                  <>
                  <h1 className="text-sm-start" style={{fontSize:"45px",marginTop:"-50px"}}>
                  Connecting
                  </h1>
                  <h1 className="text-sm-start" style={{fontSize:"45px",marginTop:"-50px"}}>
                  Technology 
                  </h1>
                  <h1 className="text-sm-start" style={{fontSize:"45px",marginTop:"-50px"}}>
                  to <em>Lead </em>
                  </h1>
                  <h1 className="text-sm-start" style={{fontSize:"45px",marginTop:"-50px"}}>
                  Innovation
                  </h1>
                  </>

                ) : (
                  <h1 style={{marginTop:"-100px"}}>
                    Connecting Technology to <em>Lead </em>Innovation
                  </h1>
                )}
                <p>
                  CEYON TECHNOLOGIES is a dynamic IT leader committed to driving
                  innovation and delivering cutting-edge solutions. Our seasoned
                  team excels in Enterprise ERP, Technology Consulting, Resource
                  Augmentation, and tailored industry solutions. With a focus on
                  exceptional customer service, we empower businesses to
                  navigate complex challenges and achieve technological
                  advancement in a rapidly evolving landscape.
                </p>
                <a href="#about" className="main-button-slider">
                  KNOW US BETTER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
