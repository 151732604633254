import React from 'react'

export const Preloader = () => {
  return (
    <div>
        <div id="preloader">
        <div className="jumper">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    </div>
  )
}
